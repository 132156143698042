import { navigate } from 'gatsby';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// UI-kit
import Button from 'ui-kit/button/button';
import ToastBox from 'ui-kit/toast-box/toast-box';

import BirdiModalErrorContent from 'components/birdi-modal/birdi-modal-error-content';
// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

// State
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { easyRefillGetCartRoutine, easyRefillSubmitOrderRoutine } from 'state/easy-refill/easy-refill.routines';

// Schema
import { formatPrice } from 'schema/price.schema';

// Utils
import { getPhoneNumber } from 'util/globalVariables';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

// Hooks
import useCart from 'hooks/useCart';
import { useEasyRefillHealthConditions } from 'hooks/useEasyRefillHealthConditions';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

// Styles
import './easy-refill-cart-total.style.scss';

export const EasyRefillCartTotal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { cartData, prescriptionsInCart } = useCart();
    const { mainUserHasInsurance } = usePrescriptionFlow();

    const [isBusy, setIsBusy] = useState<boolean>(false);

    const { userHasNotSubmittedAllergies, userHasNotSubmittedConditions } = useEasyRefillHealthConditions();

    const isOneClickOrderAvailable =
        !userHasNotSubmittedConditions &&
        !userHasNotSubmittedAllergies &&
        !!cartData?.paymentMethod &&
        !!cartData?.address;

    const showPaymentError = () => {
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'lg',
                onClose: () => {
                    dispatch(closeModal({}));
                },
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('modals.paymentMethods.paymentError.title')} icon="alert" />
                ),
                bodyContent: (
                    <BirdiModalErrorContent
                        description={t('modals.paymentMethods.paymentError.description')}
                        ctaText={t('modals.paymentMethods.paymentError.cta')}
                        onClose={() => {
                            dispatch(closeModal({}));
                        }}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleSubmitOrderClick = () => {
        setIsBusy(true);
        dispatch(
            easyRefillSubmitOrderRoutine({
                currentShippingPrice: cartData?.shipMethodPrice,
                lineItems: prescriptionsInCart?.map((rx) => rx.rxDetails) || [],
                onSuccess: () => {
                    setIsBusy(false);
                    TrackCheckoutStep({
                        stepName: 'purchase',
                        step: '1',
                        carts: cartData?.patients || [],
                        prescriptions: prescriptionsInCart || [],
                        t: t,
                        shippingCost: String(cartData?.shipMethodPrice),
                        accountHasInsurance: mainUserHasInsurance
                    });
                    navigate('/easy-refill/order-confirmation');
                },
                onFailure: (data: any) => {
                    setIsBusy(false);

                    if (
                        !!data &&
                        (data[0]?.order?.messageErrorText === 'PRE-AUTH-DENIED' ||
                            data[0]?.messageErrorText === 'PRE-AUTH-DENIED')
                    ) {
                        showPaymentError();
                        dispatch(easyRefillGetCartRoutine.trigger());
                        return;
                    }

                    dispatch(
                        openModal({
                            showClose: true,
                            size: 'lg',
                            type: 'danger',
                            headerContent: (
                                <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                            ),
                            bodyContent: (
                                <BirdiModalContent
                                    body={t(`pages.easyRefill.review.errors.orderError`, {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })}
                                />
                            ),
                            ctas: [
                                {
                                    label: t('modals.easyRefillFailure.buttonText'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'EasyRefillOrderFailed'
                                }
                            ]
                        })
                    );
                    dispatch(easyRefillGetCartRoutine.trigger());
                }
            })
        );
    };

    return (
        <div className="easy-refill-cart-total">
            <div className="easy-refill-cart-total__calculations">
                <div className="easy-refill-cart-total__calculations__subtotal">
                    <div>
                        {t('pages.cart.subTotal')}
                        {cartData?.itemHasUnknownPrice ? <sup>*</sup> : ''}:
                    </div>
                    <div>{formatPrice(cartData?.subTotal)}</div>
                </div>
                <div className="easy-refill-cart-total__calculations__shipping">
                    <div>{t('pages.cart.shipping')}</div>
                    <div>{formatPrice(cartData?.shipMethodPrice)}</div>
                </div>
            </div>
            <div className="easy-refill-cart-total__total">
                <div className="easy-refill-cart-total__total__title">
                    {t('components.medicineCabinetCart.newCartTotal.total')}
                    {cartData?.itemHasUnknownPrice ? <sup>*</sup> : ''}
                </div>
                <div className="easy-refill-cart-total__total__price">{formatPrice(cartData?.total)}</div>
            </div>
            {cartData?.itemHasUnknownPrice && mainUserHasInsurance && (
                <ToastBox icon="default" variant="info">
                    {t('pages.cart.totalDisclaimer')}
                </ToastBox>
            )}
            <div className="easy-refill-cart-total__ctas">
                <Button
                    async
                    isBusy={isBusy}
                    disabled={!isOneClickOrderAvailable || isBusy}
                    type="submit"
                    variant="primary"
                    label={t('components.medicineCabinetCart.newCartTotal.submit')}
                    onClick={handleSubmitOrderClick}
                />
                <Button
                    customFontSize="18"
                    type="button"
                    variant="text"
                    className="btn btn-text text-cerulean no-min-width"
                    label={t('components.medicineCabinetCart.newCartTotal.review')}
                    onClick={() => navigate('/easy-refill/review')}
                    disabled={isBusy}
                />
                <div className="easy-refill-cart-total__ctas__disclaimer">
                    {t('components.medicineCabinetCart.newCartTotal.disclaimer')}
                </div>
            </div>
            <br />
        </div>
    );
};
