import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import Spinner from 'ui-kit/spinner/spinner';

import EmptyCart from 'components/medicine-cabinet-cart/empty-cart/empty-cart.component';
// UI Kit & Components
import SidebarColumn from 'components/sidebar-column/sidebar-column.component';

import { isLoadingCartSelector } from 'state/easy-refill/easy-refill.selectors';

// Utils
import useCart from 'hooks/useCart';

import { EasyRefillCartTotal } from './easy-refill-cart-total/easy-refill-cart-total.component';
import EasyRefillHealthProfile from './easy-refill-health-profile/easy-refill-health-profile.component';
import EasyRefillPaymentMethod from './easy-refill-payment-method/easy-refill-payment-method.component';
import EasyRefillPrescriptionInformation from './easy-refill-prescription-information/easy-refill-prescription-information.component';
import EasyRefillShippingAddress from './easy-refill-shipping-address/easy-refill-shipping-address.component';
import EasyRefillShippingMethod from './easy-refill-shipping-method/easy-refill-shipping-method.component';
import './refill-details-cart.style.scss';

const RefillDetailsCart: React.FC = () => {
    const { t } = useTranslation();

    const isCartLoading = useSelector(isLoadingCartSelector);
    const { cartData } = useCart();

    return (
        <SidebarColumn
            className="refill-details-cart"
            isCartOnly
            cartTotalItems={cartData?.cartItems}
            cartLabel={t('pages.easyRefill.prescriptions.responsiveCart.cartButtonLabel')}
        >
            {isCartLoading && !!cartData && cartData?.cartItems > 0 && (
                <div className="refill-details-cart__loading__blocker">
                    <Spinner isVisible fullOverlay={false} t={t} color="silver" focusOnShow />
                </div>
            )}

            <div className="refill-details-cart__header">
                <CartIcon color="black" className={'header-icon-container'} />
                <h3>{t('pages.easyRefill.prescriptions.responsiveCart.cartButtonLabel')}</h3>
            </div>

            {cartData?.cartItems === 0 && isCartLoading ? (
                <div className="refill-details-cart__loading">
                    <Spinner isVisible fullOverlay={false} t={t} color="silver" />
                </div>
            ) : (
                <>
                    {cartData?.cartItems && cartData?.cartItems > 0 ? (
                        <>
                            <EasyRefillShippingAddress />
                            <EasyRefillShippingMethod />
                            <EasyRefillPaymentMethod showSelectCardRadioInput showSetDefaultLink />
                            <EasyRefillHealthProfile />
                            <EasyRefillPrescriptionInformation />
                            <EasyRefillCartTotal />
                        </>
                    ) : (
                        <>
                            {!isCartLoading && (
                                <EmptyCart
                                    title={t('components.medicineCabinetCart.empty.title')}
                                    body={t('components.medicineCabinetCart.empty.body')}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </SidebarColumn>
    );
};

export default RefillDetailsCart;
