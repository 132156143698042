import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { drugsWithDiscountSelector } from 'state/drug/drug.selectors';
import { easyRefillAdjudicatedRxsSelector, easyRefillPlanAliasSelector } from 'state/easy-refill/easy-refill.selectors';

import { AdditionalProp } from 'types/easy-refill';

export const useEasyRefill = () => {
    const drugDiscountPrices = useSelector(drugsWithDiscountSelector);

    const adjudicatedRxs = useSelector(easyRefillAdjudicatedRxsSelector);
    const easyRefillUserPlan = useSelector(easyRefillPlanAliasSelector);

    const isBirdiUser = useMemo(() => {
        return easyRefillUserPlan === 'BRD01';
    }, [easyRefillUserPlan]);

    const showBirdiPricing = useMemo(() => {
        return ALLOW_INSURED_BIRDI_PRICE && isBirdiUser ? true : false;
    }, [isBirdiUser]);

    const showRxCost = useCallback(
        (adjudicatedRx: AdditionalProp) => {
            return adjudicatedRx.patientCopay || showBirdiPricing ? true : false;
        },
        [showBirdiPricing]
    );

    const estimatedRxCost = useMemo(() => {
        // get the list of adjudicated RXs we need to get Birdi Prices for
        const rxsToGetBirdiPrice = Object.entries(adjudicatedRxs)
            .filter(([, rxDetails]) => !rxDetails.patientCopay)
            .map(([rxNumber]) => {
                return rxNumber;
            });

        const rxsToCalculateBirdiPrice = drugDiscountPrices.filter((discountDrugPrice) => {
            return (
                typeof discountDrugPrice.price === 'number' &&
                rxsToGetBirdiPrice.includes(discountDrugPrice.rxNumber || '')
            );
        });

        const rxBirdiPriceCost = rxsToCalculateBirdiPrice.reduce(
            (partialSum, priceObj) => Number(priceObj.price) + partialSum,
            0
        );

        // get the list of RXs that we have patientCopays for & sum those
        const rxsWithCopays = Object.values(adjudicatedRxs)
            .filter((rx) => rx.patientCopay)
            .reduce((partialSum, rx) => {
                return partialSum + Number(rx?.patientCopay);
            }, 0);

        return rxBirdiPriceCost + rxsWithCopays;
    }, [adjudicatedRxs, drugDiscountPrices]);

    return {
        estimatedRxCost,
        showBirdiPricing,
        showRxCost
    };
};
