// Hooks
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit & Components
import ColumnSectionEditModeToggle from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

import { FailureUpdateCartModalContent } from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { easyRefillCancelOrderLineRoutine } from 'state/easy-refill/easy-refill.routines';
import { easyRefillRxsToOrderSelector } from 'state/easy-refill/easy-refill.selectors';

// Types
import { CartOrderRx } from 'types/cart';

import { PRICING_API_LOCATION } from 'enums/pricing';

// Utils & helpers
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

import useBirdiPrice from 'hooks/useBirdiPrice';
import useCart from 'hooks/useCart';
import { useEasyRefill } from 'hooks/useEasyRefill';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

// Styles
import './easy-refill-prescription-information.style.scss';
import PrescriptionItem from './prescription-item/prescription-item.component';

export interface EasyRefillPrescriptionInformationProps {
    isToggleDisabled?: boolean;
}

const EasyRefillPrescriptionInformation: React.FC<EasyRefillPrescriptionInformationProps> = (props) => {
    const { isToggleDisabled = true } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { cartData, prescriptionsInCart } = useCart();
    const { mainUserIsCaregiver, mainUserHasInsurance } = usePrescriptionFlow();

    const easyRefillRxsToOrder = useSelector(easyRefillRxsToOrderSelector);

    const { showBirdiPricing } = useEasyRefill();
    const { getPrescriptionListPrices } = useBirdiPrice();

    const handlePrescriptionsError = React.useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.shippingOption')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const handleRemovePrescription = useCallback(
        (prescriptionToRemove: CartOrderRx) => {
            if (!prescriptionToRemove) {
                return;
            }

            const epostNumPatient = prescriptionToRemove.rxDetails.prescriptionDetail?.epostPatientNum;
            const rxNumber = prescriptionToRemove.rxDetails.rxNumber;
            const lineId = prescriptionToRemove.rxDetails.epostRxScriptId;
            const orderNum = prescriptionToRemove.rxDetails.prescriptionDetail?.epostOrderNum;

            dispatch(
                easyRefillCancelOrderLineRoutine.trigger({
                    rxNumber,
                    lineId,
                    orderNum,
                    epostNumPatient,
                    onSuccess: () => {
                        TrackCheckoutStep({
                            stepName: 'remove',
                            step: '1',
                            carts: cartData?.patients || [],
                            prescriptions: prescriptionsInCart || [],
                            t: t,
                            shippingCost: String(cartData?.shipMethodPrice),
                            accountHasInsurance: mainUserHasInsurance
                        });
                    },
                    onFailure: () => {
                        handlePrescriptionsError();
                    }
                })
            );
        },
        [cartData, prescriptionsInCart, mainUserHasInsurance, t, handlePrescriptionsError, dispatch]
    );

    useEffect(() => {
        if (showBirdiPricing) {
            getPrescriptionListPrices({
                eligiblePrescriptions: easyRefillRxsToOrder.map((prescription) => ({
                    prescription,
                    location: PRICING_API_LOCATION.CART
                }))
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, showBirdiPricing]);

    return (
        <>
            <ColumnSectionEditModeToggle
                bodyClassName="easy-refill-prescription-information__content"
                className="easy-refill-prescription-information"
                editModeContent={<></>}
                headerClassName="easy-refill-prescription-information__header"
                title="Prescription Information"
                isToggleDisabled={isToggleDisabled}
            >
                <>
                    <div className="cart-review">
                        <div className="cart-review--border-bottom">
                            {cartData?.patients.map((patient) =>
                                patient.cartRxs.map((item) => {
                                    return (
                                        <PrescriptionItem
                                            isCaregiver={mainUserIsCaregiver}
                                            patientName={`${patient.firstName} ${patient.lastName}`}
                                            key={item.rxDetails.rxNumber}
                                            onRemovePrescriptionClick={handleRemovePrescription}
                                            prescription={item}
                                            isPlanAutoRefillEligible={patient.planEligibleForAutoRefill}
                                        />
                                    );
                                })
                            )}
                        </div>
                    </div>
                </>
            </ColumnSectionEditModeToggle>
        </>
    );
};

export default EasyRefillPrescriptionInformation;
