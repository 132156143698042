import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ToastBox from 'ui-kit/toast-box/toast-box';

// Components & UI
import { CartShipping } from 'components/cart/cart-shipping.component';
import ColumnSectionEditModeToggle, {
    ColumnSectionEditModeToggleRef
} from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

// Modals
import { FailureUpdateCartModalContent } from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { easyRefillGetCartRoutine } from 'state/easy-refill/easy-refill.routines';

// Utils
import { DEFAULT_SHIPPING_ID, EXPEDITED_SHIPPING_ID } from 'util/cart';

import useCart from 'hooks/useCart';

import './easy-refill-shipping-method.style.scss';

const EasyRefillShippingMethod: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const columnSectionRef = useRef<ColumnSectionEditModeToggleRef>(null);
    const { cartData, changeCartShippingMethod } = useCart();
    const shipMethodId = useMemo(() => cartData?.shipMethodId || DEFAULT_SHIPPING_ID, [cartData]);

    const showShippingError = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.shippingOption')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const handleShippingMethodSelection = (shipMethodId: string) => {
        changeCartShippingMethod({
            shipMethodId,
            onSuccess: () => {
                dispatch(easyRefillGetCartRoutine.trigger());
                columnSectionRef?.current?.handleSaveChangesButtonClick();
            },
            onFailure: () => {
                showShippingError();
                dispatch(easyRefillGetCartRoutine.trigger());
                return false;
            }
        });
    };

    return (
        <ColumnSectionEditModeToggle
            ref={columnSectionRef}
            bodyClassName="easy-refill-shipping-method__content"
            className="easy-refill-shipping-method"
            editModeContent={
                <CartShipping
                    handleDefaultShippingClick={() => handleShippingMethodSelection(DEFAULT_SHIPPING_ID)}
                    handleExpeditedShippingClick={() => handleShippingMethodSelection(EXPEDITED_SHIPPING_ID)}
                />
            }
            headerClassName="easy-refill-shipping-method__header"
            title={t('components.medicineCabinetCart.shippingMethod.title')}
        >
            <>
                <p className="easy-refill-shipping-method__selected">
                    {t(
                        `components.medicineCabinetCart.shippingMethod.${
                            shipMethodId === EXPEDITED_SHIPPING_ID ? 'expeditedShipping' : 'freeShipping'
                        }`
                    )}
                </p>
                <ToastBox icon="default" variant="info">
                    {t('components.medicineCabinetCart.shippingMethod.footer')}
                </ToastBox>
            </>
        </ColumnSectionEditModeToggle>
    );
};

export default EasyRefillShippingMethod;
