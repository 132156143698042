// General
import { graphql, navigate } from 'gatsby';
import { ENABLE_AUTO_REFILL } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// Ui-kit
import NoRefillsIcon from 'ui-kit/illustrations/no-refills/no-refills';
import PageSection from 'ui-kit/page-section/page-section';
import Spinner from 'ui-kit/spinner/spinner';

// Display components
import TermsAndConditionsContent from 'display-components/auto-refill-modal-contents/terms-and-conditions';
import EmptyState from 'display-components/empty-state/empty-state';

//Components
import AutoRefillBanner from 'components/auto-refill-banner/auto-refill-banner.component';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import InternalHeader from 'components/internal-header/internal-header.component';
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';
import PrescriptionTabs, {
    Dependents
} from 'components/prescriptions-list/prescription-tabs/prescription-tabs.component';
import PrescriptionCard from 'components/prescriptions-list/prescriptions-card/prescriptions-card.component';
import RefillDetailsCart from 'components/refill-details-cart/refill-details-cart';

// States
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { easyRefillActions } from 'state/easy-refill/easy-refill.reducer';
import {
    easyRefillFetchHealthConditionsRoutine,
    easyRefillGetCartRoutine,
    easyRefillGetPatientAddressesRoutine,
    easyRefillGetPatientDataRoutine,
    easyRefillGetPatientPaymentCardsRoutine,
    easyRefillGetPatientPlansRoutine,
    easyRefillStartOrderRoutine
} from 'state/easy-refill/easy-refill.routines';
import {
    easyRefillAccountIsCaliforniaUserSelector,
    easyRefillActiveTabSelector,
    easyRefillAddressesSelector,
    easyRefillBusySelector,
    easyRefillEpostPatientNumSelector,
    easyRefillFamilyDependentsSelector,
    easyRefillFamilyMembersSelector,
    easyRefillFirstNameSelector,
    easyRefillLoadingCartSelector,
    easyRefillLoadingRxsSelector,
    easyRefillPatientAutoRefillSelector,
    easyRefillPaymentCardsSelector,
    easyRefillPlansSelector,
    easyRefillRemoveCartBtnDisabled,
    easyRefillRxsLoadedSelector,
    easyRefillSelector,
    easyRefillToggleBusySelector,
    easyRefillUserBearerTokenSelector,
    isLoadingPrescriptionsSelector
} from 'state/easy-refill/easy-refill.selectors';

// Interfaces and Types
import { GetEasyRefillPatientDataResponse } from 'types/easy-refill';

import { RX_AVAILABLE_FLOWS } from 'enums/prescription';

// Util
import { getPhoneNumber } from 'util/globalVariables';
import storageHelper from 'util/storageHelper';
import { capitalizeFirstLetter, lowercaseAndCapitalize } from 'util/string';

// Hooks
import { useAutoRefillToggle } from 'hooks/useAutoRefillToggle';
import useCart from 'hooks/useCart';
import { useEasyRefillHealthConditions } from 'hooks/useEasyRefillHealthConditions';
import usePrescriptionCards from 'hooks/usePrescriptionCards';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

// HOC
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';

import { EasyRefillErrorModal } from '..';
import './index.style.scss';

// Main component
const EasyRefillPrescriptions = ({
    data
}: {
    data: GatsbyTypes.GetEasyRefillPrescriptionLanguageAndImageDataQuery;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { cartData, prescriptionsInCart } = useCart();
    const { blueSkyBackground } = data;
    const { prescriptionCards } = usePrescriptionCards();
    const { mainUserIsCaregiver } = usePrescriptionFlow();

    // --------------------
    // Selectors
    // -------------------
    const firstName = useSelector(easyRefillFirstNameSelector);
    const easyRefillLoadingRxs = useSelector(easyRefillLoadingRxsSelector);
    const easyRefillRxsLoaded = useSelector(easyRefillRxsLoadedSelector);
    const easyRefillBearerToken = useSelector(easyRefillUserBearerTokenSelector);
    const easyRefillEPostPatientNumber = useSelector(easyRefillEpostPatientNumSelector);
    const easyRefillFamilyDependents = useSelector(easyRefillFamilyDependentsSelector);
    const easyRefill = useSelector(easyRefillSelector);
    const easyRefillIsBusy = useSelector(easyRefillBusySelector);
    const easyRefillCartLoading = useSelector(easyRefillLoadingCartSelector);
    const isCaliforniaUser = useSelector(easyRefillAccountIsCaliforniaUserSelector);
    const isAutoRefillToggleBusy = useSelector(easyRefillToggleBusySelector);
    const removeCartBtnDisabled = useSelector(easyRefillRemoveCartBtnDisabled);
    const accountPlans = useSelector(easyRefillPlansSelector);
    const patientPaymentCards = useSelector(easyRefillPaymentCardsSelector);
    const patientAddressses = useSelector(easyRefillAddressesSelector);
    const userAutoRefill = useSelector(easyRefillPatientAutoRefillSelector);
    const isLoadingPatientData = useSelector(isLoadingPrescriptionsSelector);
    const healthConditions = useEasyRefillHealthConditions();
    const familyMembers = useSelector(easyRefillFamilyMembersSelector);
    const activeTab = useSelector(easyRefillActiveTabSelector);
    const { handleToggleAutoRefill } = useAutoRefillToggle();

    // // --------------------
    // // Local state
    // // -------------------
    const [isRxBusy, setIsRxBusy] = useState<boolean>(false);
    const [planAllowsAutoRefill, setPlanAllowsAutoRefill] = useState<boolean>(false);

    // // --------------------
    // // Memoized values
    // // -------------------

    const shouldDisplayAutoRefillInfo = useMemo(() => {
        if (mainUserIsCaregiver) {
            return easyRefillFamilyDependents.some((dependent) =>
                dependent.rxResults.some(
                    (prescription) => prescription.autoRefillEligible && !prescription.autoRefillEnabled
                )
            );
        } else {
            return easyRefill.autoRefillFlags?.some((flag) => flag.autoRefillEligible && !flag.autoRefillEnabled);
        }
    }, [easyRefill.autoRefillFlags, easyRefillFamilyDependents, mainUserIsCaregiver]);

    // // --------------------
    // // Callbacks
    // // -------------------

    // Display error modal
    const handleShowErrorModal = useCallback(
        (errorText: string) => {
            dispatch(
                openModal({
                    showClose: true,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                    ),
                    bodyContent: <EasyRefillErrorModal translation={t} errorMessage={errorText} />,
                    ctas: [
                        {
                            label: t('modals.easyRefillFailure.buttonText'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'EasyRefillNotVerified'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    const handleAddToCartClick = useCallback((rxNumber: string, epostPatientNum: string) => {
        setIsRxBusy(true);
        // Convert variables to strings if they are not already strings
        rxNumber = typeof rxNumber !== 'string' ? String(rxNumber) : rxNumber;
        epostPatientNum = typeof epostPatientNum !== 'string' ? String(epostPatientNum) : epostPatientNum;
        // Check if the item is already in the cart
        const rxInCart = prescriptionsInCart?.some((cartItem) => cartItem.rxDetails.rxNumber === rxNumber);
        if (!rxInCart) {
            dispatch(
                easyRefillStartOrderRoutine.trigger({
                    rxNumber: rxNumber,
                    epostPatientNum: epostPatientNum,
                    onSuccess: () => {
                        dispatch(
                            easyRefillGetCartRoutine.trigger({
                                onSuccess: () => {
                                    dispatch(closeModal({}));
                                    setIsRxBusy(false);
                                }
                            })
                        );
                        dispatch(easyRefillGetPatientDataRoutine.trigger({ withoutLoading: true }));
                    },
                    onFailure: () => {
                        setIsRxBusy(false);
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'alert'}
                                        title={t(`pages.medicineCabinet.messages.callbacks.error`)}
                                        body={t(`pages.medicineCabinet.messages.callbacks.errorMessage`)}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        }
    }, []);

    // Handle Auto refill toggle click
    const onChangeAutoRefill = useCallback(
        (rxNumber: string, rxSeqNumber: string, autoRefillEnabled: boolean, isRenew?: boolean) => {
            const hasAnyRxAutorefillOn = prescriptionCards?.some((rx) => rx.autoRefillEnabled);
            handleToggleAutoRefill(
                { rxNumber: rxNumber, rxSeqNum: rxSeqNumber },
                autoRefillEnabled,
                hasAnyRxAutorefillOn,
                <TermsAndConditionsContent />,
                isRenew
            );
        },
        [handleToggleAutoRefill, prescriptionCards]
    );

    // Function that handles a tab change
    // TODO: When refactoring consider another approach to don't need to
    // pass an unused tab property.
    const handleTabItemClick = (tab = 'all', dependent: string | null) => {
        dispatch(easyRefillActions.setActiveTab(dependent));
    };

    const formatFamilyMembersData = (data: GetEasyRefillPatientDataResponse[]): Dependents[] => {
        if (data.length <= 0) return [];
        const dependents: Dependents[] = data.map(
            (item: GetEasyRefillPatientDataResponse, key): Dependents => ({
                ePostPatientNum: item.epostPatientNum,
                familyMemberName: lowercaseAndCapitalize(`${item.firstName || ''} ${item.lastName || ''}`),
                id: key
            })
        );
        return dependents;
    };

    const processedGreeting = useCallback(() => {
        if (firstName) {
            const hasRxs = mainUserIsCaregiver
                ? cartData?.patients.some((patient) => patient.cartRxs?.length > 0)
                : prescriptionCards.length > 0;
            const capitalizedFirstName = capitalizeFirstLetter(firstName.toLocaleLowerCase());
            return hasRxs ? (
                <p>
                    {t('pages.easyRefill.prescriptions.greeting', {
                        firstName: capitalizedFirstName
                    })}
                </p>
            ) : (
                <p>
                    {t('pages.easyRefill.prescriptions.greetingEmpty', {
                        firstName: capitalizedFirstName
                    })}
                </p>
            );
        }
        return <p>{t('pages.easyRefill.prescriptions.greetingEmptyNoName')}</p>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [easyRefillLoadingRxs]);

    // Function that renders Rxs for family members.
    const renderPrescriptions = useCallback(() => {
        return (
            <>
                {prescriptionCards?.length === 0 ? (
                    <div className={mainUserIsCaregiver ? 'empty-state-container' : 'easy-refill-prescriptions-empty'}>
                        <EmptyState
                            icon={NoRefillsIcon}
                            title={t('components.emptyState.noRefills.easyRefill.title')}
                            description={t('components.emptyState.noRefills.easyRefill.description')}
                        />
                    </div>
                ) : (
                    prescriptionCards &&
                    prescriptionCards.map((prescription, index) => (
                        <Col
                            className="p-0 mb-4 flex-fill auto-refill-rx-card"
                            key={`easy-refill-rx-card-${prescription.rxNumber}-${index}`}
                        >
                            <PrescriptionCard
                                {...prescription}
                                isAddingToCart={isRxBusy || isLoadingPatientData}
                                isCollapsed={false}
                                autoRefillToggle={onChangeAutoRefill}
                                isCaliforniaUser={isCaliforniaUser}
                                autoRefillToggleBusy={
                                    !!(
                                        easyRefillCartLoading ||
                                        isAutoRefillToggleBusy ||
                                        easyRefillIsBusy ||
                                        isLoadingPatientData
                                    )
                                }
                                planAllowsAutoRefill={mainUserIsCaregiver ? planAllowsAutoRefill : userAutoRefill}
                                flow={RX_AVAILABLE_FLOWS.EASY_REFILL}
                                addToCart={() => {
                                    handleAddToCartClick(
                                        prescription.rxNumber,
                                        prescription.fullRxItem.epostPatientNum
                                    );
                                }}
                            />
                        </Col>
                    ))
                )}
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        prescriptionsInCart,
        easyRefillIsBusy,
        easyRefillCartLoading,
        isAutoRefillToggleBusy,
        removeCartBtnDisabled,
        isRxBusy,
        prescriptionCards,
        isCaliforniaUser,
        isLoadingPatientData,
        onChangeAutoRefill,
        handleAddToCartClick
    ]);

    // --------------------
    // Use effect
    // -------------------

    // Check if the token is valid
    useEffect(() => {
        if (!easyRefillBearerToken) {
            navigate('/link-expired?flow=easy-refill');
        }
    }, [easyRefillBearerToken]);

    // DRX-3165: This useEffect handles post-order actions in Easy Refill.
    // It verifies if an order has been placed by checking a specific cookie.
    // If an order exists, it dispatches actions to fetch patient data and cart details,
    // then removes the order placement cookie to prevent redundant triggers.
    useEffect(() => {
        const hasPlacedAnOrder = storageHelper.cookies.getHasOrderEasyRefillPlaced();

        if (hasPlacedAnOrder) {
            dispatch(easyRefillGetPatientDataRoutine.trigger({}));
            dispatch(easyRefillGetCartRoutine.trigger());
            storageHelper.cookies.removeHasOrderEasyRefillPlaced();
        }
    }, [dispatch]);

    /***************************************************************************************************/

    /************************************  Patient Data Api Call   ************************************/

    // DRX-2425: Due to various distinct requirements causing a potential performance loss
    // when the flow is invoked multiple times, this useEffect is placed at the top
    // to fetch the information once.

    useEffect(() => {
        if (easyRefillRxsLoaded) return;

        // Get Patient Data
        // do not call the patient data if the rxResults already populated

        if (easyRefillEPostPatientNumber) dispatch(easyRefillActions.setActiveTab(easyRefillEPostPatientNumber));
        dispatch(
            easyRefillGetPatientDataRoutine.trigger({
                onFailure: () => {
                    handleShowErrorModal(
                        t('pages.easyRefill.prescriptions.errorRetrievingData', {
                            phoneNumber: getPhoneNumber({ isEnd: true })
                        })
                    );
                }
            })
        );

        if (patientAddressses.length === 0) dispatch(easyRefillGetPatientAddressesRoutine.trigger());
        if (patientPaymentCards.length === 0) dispatch(easyRefillGetPatientPaymentCardsRoutine.trigger());
        if (!healthConditions.ePostPatientNum) dispatch(easyRefillFetchHealthConditionsRoutine.trigger());
        // Start the Cart process by getting all the items from the Easy Caregiver Cart.
        dispatch(easyRefillGetCartRoutine.trigger());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // DRX-2425: Because of variations in response objects for patient data, we need to dispatch API calls differently.
    // In caregiver accounts, the call must be made during the mapping of family members through the familyMembers validation.
    // However, in normal accounts, we directly call the API with the EpostpatientNum for the patient in the flow.
    useEffect(() => {
        if (!accountPlans?.length && familyMembers) {
            dispatch(easyRefillGetPatientPlansRoutine({ familyMembers: familyMembers }));
        } else if (!mainUserIsCaregiver && !accountPlans?.length && easyRefillEPostPatientNumber) {
            dispatch(easyRefillGetPatientPlansRoutine({ familyMembers: [easyRefillEPostPatientNumber] }));
        }
    }, [accountPlans, dispatch, easyRefillEPostPatientNumber, mainUserIsCaregiver, easyRefillRxsLoaded, familyMembers]);

    // /***************************************************************************************************/

    useEffect(() => {
        if (!easyRefillFamilyDependents.length || !activeTab) {
            setPlanAllowsAutoRefill(userAutoRefill);
            return;
        }

        const activeDependentInfo = easyRefillFamilyDependents.find(
            (dependent) => dependent.epostPatientNum === activeTab
        );

        if (!activeDependentInfo) return;

        setPlanAllowsAutoRefill(!!activeDependentInfo.patientAutoRefill);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [easyRefillFamilyDependents, activeTab]);

    return (
        <MarketingPageLayout
            headerImage={blueSkyBackground}
            headerImageClassName="profile-background"
            metaData={{ nodeTitle: 'Easy Refill' }}
            suppressApplicationPage={true}
        >
            <PageSection>
                <Container fluid className="easy-refill-prescriptions">
                    <InternalHeader
                        title={t('pages.easyRefill.prescriptions.headlineText')}
                        eyebrowText={t('pages.easyRefill.prescriptions.eyebrowText')}
                        sectionIndex={0}
                        body={
                            easyRefillLoadingRxs ? (
                                <Spinner isVisible={easyRefillLoadingRxs} t={t} />
                            ) : (
                                processedGreeting()
                            )
                        }
                    />

                    <div className="easy-refill-prescriptions-content">
                        <div className="easy-refill-prescriptions-content-prescriptions">
                            {ENABLE_AUTO_REFILL &&
                                shouldDisplayAutoRefillInfo &&
                                !easyRefillLoadingRxs &&
                                planAllowsAutoRefill && <AutoRefillBanner />}
                            {/* Select all Rxs normal patients */}

                            {/* DRX-1760: This will we a future implementation */}

                            {/* {!easyRefillLoadingRxs && !easyRefillIsCaregiver && (
                                    <Row>
                                        <Checkbox
                                            id="select-all-checkbox"
                                            label={t('pages.easyRefill.prescriptions.refillAllPrescriptions')}
                                            onClick={() => {}}
                                            onCheckChanged={() => {}}
                                        />
                                    </Row>
                                )} */}
                            {!easyRefillLoadingRxs && mainUserIsCaregiver && (
                                <Col xs={12} md={12} lg={12}>
                                    <Row>
                                        {easyRefillFamilyDependents.length > 0 && (
                                            <Col>
                                                <PrescriptionTabs
                                                    isUnAuthFlow
                                                    activeTab={'all'}
                                                    activeDependentTab={activeTab || easyRefillEPostPatientNumber}
                                                    isPrescriptionsAvailable={false}
                                                    onTabItemChange={handleTabItemClick}
                                                    dependents={
                                                        formatFamilyMembersData(easyRefillFamilyDependents) || []
                                                    }
                                                    myEpostPatientNum={easyRefillEPostPatientNumber}
                                                    hasFilterTabs={false}
                                                    hasAutoRefillFlag={false}
                                                />
                                            </Col>
                                        )}

                                        {/* Select all Rxs for Caregivers */}

                                        {/* DRX-1760: This will we a future implementation */}

                                        {/* {!easyRefillLoadingRxs && easyRefillIsCaregiver && (
                                                    <Row className="w-100 easy-refill-select-all">
                                                        <Checkbox
                                                            id="select-all-checkbox"
                                                            label={t(
                                                                'pages.easyRefill.prescriptions.refillAllPrescriptions'
                                                            )}
                                                            onClick={() => {}}
                                                            onCheckChanged={() => {}}
                                                        />
                                                    </Row>
                                                )} */}

                                        {!easyRefillLoadingRxs && !!easyRefillBearerToken && renderPrescriptions()}
                                    </Row>
                                </Col>
                            )}
                            {!easyRefillLoadingRxs && !mainUserIsCaregiver && !!easyRefillBearerToken && (
                                <>{renderPrescriptions()}</>
                            )}
                        </div>
                        {!easyRefillLoadingRxs && <RefillDetailsCart />}
                    </div>
                </Container>
            </PageSection>
        </MarketingPageLayout>
    );
};

export default withUnauthenticatedSessionExpiration(EasyRefillPrescriptions, 'easy-refill');

export const query = graphql`
    query GetEasyRefillPrescriptionLanguageAndImageData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background-02.png" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
